<template>
  <main class="main">
    <div class="main-inner">
      <navbar />
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <foot />
    <back-to-top bottom="50px" right="50px">
      <button type="button" class="btn btn-info btn-to-top">
        <i class="fa fa-chevron-up"></i>
      </button>
    </back-to-top>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>